/* eslint-env browser */

/**
 * Class to create the navigation drawer that is displayed on small screens
 *
 * The menu is cloned from the #main-navigation-menu element (a list)
 * Additional hyperlinks are added having a data-dc-menu-extra attribute
 */
class Navigation {
  static navdialog = '#dialog-drawer'

  /**
   * Constructor
   *
   * @returns {Navigation}
   */
  constructor () {
    this.dialog = document.querySelector(Navigation.navdialog)
  }

  /**
   * @param {string} logoPath
   * @returns {void}
   */
  init (logoPath) {
    this.dialog.addEventListener('click', (event) => {
      if (event.target.nodeName === 'DIALOG') {
        this.dialog.close()
      }
    })

    document.getElementById('dc-menu-toggle').addEventListener('click', e => this.dialog.showModal())

    this.populateDrawer(logoPath)
    this.fixMenuOpeningPositioning()
  }

  /**
   * Fixes the display of of a menu to avoid it going over the viewport by toggling a rightalign class
   *
   * @returns {void}
   */
  fixMenuOpeningPositioning () {
    const observer = new IntersectionObserver((items) => {
      items.filter((item) => { return item.isIntersecting && item.intersectionRatio < 1 }).forEach((item) => {
        // check if the item bounding box is to the right of the viewport
        if (item.boundingClientRect.right > item.rootBounds.right) {
          item.target.classList.toggle('showonleft')
        }
      })
    }, { threshold: 0.1 })

    document.querySelectorAll('.dc-menu ul').forEach((elem) => {
      observer.observe(elem)
    })
  }

  /**
   * Populate the navigation drawer
   *
   * @param {string} logoPath
   * @returns {void}
   */
  populateDrawer (logoPath) {
    const drawContent = this.dialog.querySelector('nav')

    const menu = document.querySelector('#main-navigation-menu').cloneNode(true)

    menu.classList.replace('dc-menu', 'dc-menu-drawer')
    menu.classList.remove('hidden', 'sm:block')
    menu.removeAttribute('id')

    drawContent.appendChild(menu)

    this.populateExtraItemsInDrawer(drawContent)

    const logo = document.createElement('img')
    logo.src = logoPath
    drawContent.appendChild(logo)
  }

  /**
   * Adds the data-dc-menu-extra items to the drawer menu
   *
   * @param {HTMLElement} drawContent
   * @returns {void}
   */
  populateExtraItemsInDrawer (drawContent) {
    const extra = document.querySelectorAll('a[data-dc-menu-extra]')

    if (extra.length === 0) {
      return
    }

    const ul = document.createElement('ul')

    extra.forEach(function (link) {
      const li = document.createElement('li')
      const copy = link.cloneNode(true)

      li.appendChild(copy)
      ul.appendChild(li)
    })

    drawContent.appendChild(document.createElement('hr'))
    drawContent.appendChild(ul)
  }
}

export { Navigation }
