class CookieDismiss {
  constructor () {
    const cookiebox = document.querySelector('#euCookieDirective')

    if (cookiebox) {
      cookiebox.style.height = cookiebox.scrollHeight + 'px'
      cookiebox.style.transition = 'all 1s ease-in-out'
      cookiebox.style.overflow = 'hidden'

      cookiebox.querySelector('a:last-of-type').addEventListener('click', (event) => {
        event.preventDefault()
        cookiebox.style.height = 0
        cookiebox.style.opacity = 0
      })
    }
  };
}

export { CookieDismiss }
