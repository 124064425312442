import ProductTableControls from '../components/ProductTableControls.svelte'
import ZoomImage from 'Frontend/svelte/ZoomImages.svelte'

class ProductBahaviour {
  init () {
    const products = document.querySelectorAll('.product')
    const container = document.querySelector('#products')

    if (products.length === 0) {
      return
    }

    this.dialogDetail = document.querySelector('#dialog-product-detail')
    this.dialogDetail.querySelector('div:first-child button').addEventListener('click', e => this.dialogDetail.close())
    this.dialogDetail.addEventListener('click', (event) => {
      if (event.target.nodeName === 'DIALOG') {
        this.dialogDetail.close()
      }
    })

    this.dialogCartAdd = document.querySelector('#dialog-cart-add')
    this.dialogCartAdd.querySelector('div:last-child button').addEventListener('click', e => this.dialogCartAdd.close())
    this.dialogCartAdd.addEventListener('click', (event) => {
      if (event.target.nodeName === 'DIALOG') {
        this.dialogCartAdd.close()
      }
    })

    this.setupZoomImages()

    this.setupProductControls(products)

    if (container) {
      this.setupDetailLink(container)

      document.querySelector('#products-filter-button').addEventListener('click', () => {
        document.querySelector('#products-filters').classList.toggle('hidden')
      })
    }
  }

  /**
   * Initialises the table and form controls
   *
   * @param {NodeList} products
   *
   * @returns {void}
   */
  setupProductControls (products) {
    products.forEach(product => {
      const productControl = product.querySelector('.product-controls')

      const rows = productControl.querySelectorAll('tbody tr')

      const sizes = []

      rows.forEach(row => {
        sizes.push({
          size: row.dataset.size,
          spg_min: row.dataset.spgMin,
          spg_max: row.dataset.spgMax,
          parts: JSON.parse(row.dataset.parts)
        })
      })

      productControl.innerHTML = ''

      const control = new ProductTableControls({
        target: productControl,
        props: {
          UrlAddToBasket: product.dataset.urladdtobasket,
          MasterPart: product.dataset.masterpart,
          sizes
        }
      })
    })
  }

  /**
   * sets up the link to show the product detailed information in a modal
   *
   * @param {HTMLElement} container
   *
   * @returns {void}
   */
  setupDetailLink (container) {
    container.addEventListener('click', (event) => {
      if (event.target.closest('a.product-detail')) {
        event.preventDefault()
        this.showProductDetailLink(event.target.closest('a.product-detail'))
      }
    })
  }

  /**
   * Show the modal for product detail
   *
   * @param {HTMLElement} link
   *
   * @returns {void}
   */
  showProductDetailLink (link) {
    fetch(link.getAttribute('href'), {
      method: 'get',
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        return response.text()
      })
      .then(result => {
        document.querySelector('#dialog-product-detail-content').innerHTML = result

        this.setupProductControls(document.querySelectorAll('#dialog-product-detail-content'))
        this.setupZoomImages()
      })
      .catch(error => {
        console.error('The part could not be shown:', error)

        document.querySelector('#dialog-product-detail-content').innerHTML = '<p class="">The product could not be loaded</p>'
      })
      .finally(() => {
        this.dialogDetail.showModal()
      })
  }

  /**
   * Sets up the zooming of the product images
   *
   * @returns {void}
   */
  setupZoomImages () {
    const productImages = document.getElementById('product-images')

    if (productImages) {
      productImages.querySelector('img').remove()

      const zoomimage = new ZoomImage({
        target: productImages,
        props: {
          images: JSON.parse(productImages.dataset.images),
          imagealt: 'Product Image'
        }
      })
    }
  }
}

export { ProductBahaviour }
