import { library as faLibrary, dom as faDom } from '@fortawesome/fontawesome-svg-core'

import { faApplePay } from '@fortawesome/free-brands-svg-icons/faApplePay'
import { faCcAmex } from '@fortawesome/free-brands-svg-icons/faCcAmex'
import { faCcJcb } from '@fortawesome/free-brands-svg-icons/faCcJcb'
import { faCcMastercard } from '@fortawesome/free-brands-svg-icons/faCcMastercard'
import { faCcStripe } from '@fortawesome/free-brands-svg-icons/faCcStripe'
import { faCcVisa } from '@fortawesome/free-brands-svg-icons/faCcVisa'
import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook'
import { faGooglePay } from '@fortawesome/free-brands-svg-icons/faGooglePay'
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram'
// import {faPinterestP} from '@fortawesome/free-brands-svg-icons/faPinterestP';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons/faWhatsapp'

import { faAt } from '@fortawesome/pro-regular-svg-icons/faAt'
import { faBan } from '@fortawesome/pro-solid-svg-icons/faBan'
import { faBars } from '@fortawesome/pro-solid-svg-icons/faBars'
// import { faBus } from '@fortawesome/pro-solid-svg-icons/faBus'
import { faCar } from '@fortawesome/pro-solid-svg-icons/faCar'
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight'
import { faClock } from '@fortawesome/pro-solid-svg-icons/faClock'
import { faCogs } from '@fortawesome/pro-solid-svg-icons/faCogs'
import { faCreditCard } from '@fortawesome/pro-solid-svg-icons/faCreditCard'
import { faDownload } from '@fortawesome/pro-solid-svg-icons/faDownload'
import { faEdit } from '@fortawesome/pro-solid-svg-icons/faEdit'
import { faFile } from '@fortawesome/pro-solid-svg-icons/faFile'
import { faImage } from '@fortawesome/pro-solid-svg-icons/faImage'
import { faKey } from '@fortawesome/pro-solid-svg-icons/faKey'
import { faList } from '@fortawesome/pro-solid-svg-icons/faList'
import { faMoneyBillWave } from '@fortawesome/pro-solid-svg-icons/faMoneyBillWave'
import { faPencilAlt } from '@fortawesome/pro-solid-svg-icons/faPencilAlt'
import { faPhone } from '@fortawesome/pro-regular-svg-icons/faPhone'
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus'
import { faRotate } from '@fortawesome/pro-solid-svg-icons/faRotate'
import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch'
import { faShoppingBasket } from '@fortawesome/pro-solid-svg-icons/faShoppingBasket'
import { faSignOutAlt } from '@fortawesome/pro-solid-svg-icons/faSignOutAlt'
import { faSort } from '@fortawesome/pro-solid-svg-icons/faSort'
// import { faStreetView } from '@fortawesome/pro-solid-svg-icons/faStreetView'
// import { faSubway } from '@fortawesome/pro-solid-svg-icons/faSubway'
import { faSync } from '@fortawesome/pro-solid-svg-icons/faSync'
import { faTrash } from '@fortawesome/pro-solid-svg-icons/faTrash'
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser'
import { faUsers } from '@fortawesome/pro-solid-svg-icons/faUsers'
import { faXmark } from '@fortawesome/pro-solid-svg-icons/faXmark'

faLibrary.add(
  faApplePay,
  faCcAmex,
  faCcJcb,
  faCcMastercard,
  faCcStripe,
  faCcVisa,
  faFacebook,
  faGooglePay,
  faInstagram,
  // faPinterestP,
  faTwitter,
  faWhatsapp,

  faAt,
  faBan,
  faBars,
  //  faBus,
  faCar,
  faCheck,
  faChevronRight,
  faClock,
  faCogs,
  faCreditCard,
  faDownload,
  faEdit,
  faFile,
  faKey,
  faImage,
  faList,
  faMoneyBillWave,
  faPencilAlt,
  faPhone,
  faPlus,
  faRotate,
  faSearch,
  faShoppingBasket,
  faSignOutAlt,
  faSort,
  //  faStreetView,
  //  faSubway,
  faSync,
  faTrash,
  faUser,
  faUsers,
  faXmark
)

faDom.watch()
