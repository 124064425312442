<script>
	/**
	 * url for adding to basket
	 *
	 * @type String
	 */
	export let UrlAddToBasket;

	/**
	 * The master part
	 *
	 * @type String
	 */
	export let MasterPart;

	/**
	 * 
	 * @type Array
	 */
	export let sizes = [];

	/**
	 * @type HTMLDialogElement
	 */
	let dialogCartAdd = document.querySelector('#dialog-cart-add')

	/**
	 * @type HTMLDialogElement
	 */
	let dialogDetail = document.querySelector('#dialog-product-detail')
	
	/**
	 * Selected size
	 *
	 * @type Number
	 */
	let selectedSize = 0;

	/**
	 * Selected size group
	 *
	 * @type Number
	 */
	let selectedSizeGroup = 0;

	/**
	 * Only a single size
	 *
	 * @type Boolean
	 */
	let singleSize = (sizes.length === 1);

	/**
	 * Only a single part
	 *
	 * @type Boolean
	 */
	let singlePart = (singleSize && sizes[0].parts.length === 1);

	/**
	 * Is it a mixed colour
	 *
	 * @type Boolean
	 */
	let isMixedColour = /^Base.+/.test(sizes[0].parts[0].colour);

	/**
	 * Handle clicking on the product table
	 * 
	 * @param {Event} event
	 *
	 * @returns {void}
	 */
//	let handleTableClick = function(event) {
//		if (event.target.closest('tr'))
//		{
//			selectedSize = event.target.closest('tr').rowIndex - 1;
//		}
//	};

	/**
	 * Handle adding a product to the basket
	 *
	 * @returns {void}
	 */
	let handleAddToBasket = function() {

		const formData	= new FormData();
		const quantity	= this.querySelector("input[name='quantity']");
		const mixedcol	= this.querySelector("input[name='mixedcolour']");

		formData.append('part', this.querySelector("input[name='part'], select[name='part']").value);
		formData.append('quantity', quantity.value);
		
		if (isMixedColour)
		{
			formData.append('mixedcolour', mixedcol.value);
		}

		fetch(UrlAddToBasket,{
				method: 'post',
				body: formData
		})
		.then(response => {
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			return response.json();
		})
		.then(result => {
			document.querySelector('#dialog-cart-add h4').innerHTML = 'Product added to cart';
			document.querySelector('#dialog-cart-add-content').innerHTML = result.details;
			dialogCartAdd.showModal();
		})
		.catch(error => {
			console.error('The part could not be added to the basket:', error);
	
			document.querySelector('#dialog-cart-add h4').innerHTML = 'Product was not added to cart';
			document.querySelector('#dialog-cart-add-content').innerHTML = '<p class="callout alert">The product could not be added to the cart</p>';
			dialogCartAdd.showModal();
		})
		.finally(function() {
			if (dialogDetail.open) {
				dialogDetail.close()
			}
			
			quantity.value = 1;
		});
	};

</script>

<style>

.add-to-basket-form {
	grid-template-columns: 1fr 3fr;
}
	
</style>

<!-- Table
<table>
	<col/><col/>
	<thead><tr><th>Size</th><th>Price (&pound;)</th></tr></thead>
	<tbody on:click={handleTableClick}>
{#each sizes as size, i}
		<tr class:highlighted-row="{i == selectedSize && !singleSize}">
			<td>{ size.size } {#if size.parts.length === 1}{ size.parts[0].colour }{/if}</td>
			<td>{ size.spg_min } {#if size.spg_min != size.spg_max}- { size.spg_max }{/if}</td>
		</tr>
{/each}
	</tbody>
</table>
-->

<form method="post"
	  on:submit|preventDefault={handleAddToBasket}
	  class="add-to-basket-form grid gap-4">
<!-- Size Field -->
	<div class="text-right py-2">
		<label for="size_{ MasterPart }">Size:</label>
	</div>
	<div>
{#if singlePart}
		<input id="size_{ MasterPart }"
			   type="text"
			   value="{ sizes[0].size }"
			   readonly="readonly"
			   class="w-full border-0 focus:ring-0" />
{:else}
		<select bind:value="{selectedSize}" id="size_{ MasterPart }" class="form-widget">
	{#each sizes as size, i}
	{#if isMixedColour}
			<option value="{i}">{ size.size } Tinted Colour (&pound;{ size.parts[0].spg })</option>		
	{:else}
			<option value="{i}">{ size.size } {#if size.parts.length === 1}{ size.parts[0].colour }{/if}</option>
	{/if}
	{/each}
		</select>
{/if}
	</div>

<!-- Parts (Colours) -->
{#if sizes[selectedSize].parts.length === 1}
{#if isMixedColour}
	<div class="text-right py-2">
		<label for="colour_{ MasterPart }">Colour:</label>
	</div>
	<div>
		<input id="colour_{ MasterPart }"
			   type="text"
			   name="mixedcolour"
			   value=""
			   placeholder="Tinted Colour Reference"
			   required="required"
			   class="form-widget" />
		<input type="hidden"
			   name="part"
			   value="{sizes[selectedSize].parts[0].part}" />
	</div>
{:else}
	<div class="text-right py-2">
		<label for="price_{ MasterPart }">Price:</label>
	</div>
	<div>
		<input id="price_{ MasterPart }"
			   type="text"
			   value="&pound; {sizes[selectedSize].parts[0].spg}"
			   readonly="readonly"
			   class="w-full border-0 focus:ring-0" />
		<input type="hidden"
			   name="part"
			   value="{sizes[selectedSize].parts[0].part}" />
	</div>
{/if}
{:else}
	<div class="text-right py-2">
		<label for="part_{ MasterPart }">Colour:</label>
	</div>
	<div>
		<select id="part_{ MasterPart }"
				name="part"
				class="form-widget">
	{#each sizes[selectedSize].parts as part}
			<option value="{ part.part }">{ part.colour } (&pound;{ part.spg })</option>
	{/each}
		</select>
	</div>
{/if}

<!-- Quantity Field-->
	<div class="text-right py-2">
		<label for="qty_{ MasterPart }">Quantity:</label>
	</div>
	<div>
		<input type="number"
			   name="quantity"
			   id="qty_{ MasterPart }"
			   min="0"
			   value="1"
			   required="required"
			   class="form-widget"
			   on:focus="{e => {if (e.target.value == 1){e.target.value = ''}}}"/>
	</div>
	<div></div>
	<div>
		<button type="submit" class="form-button"><i class="fa-solid fa-plus mr-2"></i>Add</button>
	</div>
</form>