import './styles/tailwind.css'

import './js/fontawsome.js'

// site specific

import { ProductBahaviour } from './js/modules/productBehaviour.js'
import { CookieDismiss } from './js/modules/site_cookiedismiss.js'
import { Navigation } from 'Frontend/js/Navigation.js'

/**
 * Initialises the js
 *
 * @returns void
 */
document.addEventListener('DOMContentLoaded', function () {
  const productBehavior = new ProductBahaviour()
  const cookieDismiss = new CookieDismiss()

  productBehavior.init()

  if (document.querySelector('#main-navigation-menu')) {
    const navigation = new Navigation()

    navigation.init('/images/logo.svg')
  }
})
